import React from 'react'
import styled from 'styled-components'
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Tabs,
	Tab,
	AppBar
} from '@material-ui/core'

import { IContractorJob } from '../../../pages/jobs'
import JobRowItem from './JobRowItem'
import ContractorJobModal from '../../modals/ContractorJobModal'
import ResponsiveModal from '../../ui/ResponsiveModal'
import { mapToContractorJob } from './BiddableJobs'
import { useGetContractorJobs } from '../../../apollo/queries'
import ResponsiveTable from '../../ui/ResponsiveTable'
import FilterView, { ISelectedFilters } from '../../ui/FilterView'
import Loading from '../../ui/Loading'
import queryString from 'query-string'
import Snackbar from '@material-ui/core/Snackbar'
import Pagination from '../../ui/Pagination'
import { TradeGroup } from '../../../apollo/generated'
import { navigate } from 'gatsby'

const openText = 'Once completed, change the status of the job to "Completed"'
const closedText =
	'These finished jobs can be marked "Re-Opened" by the clients'

const OpenJobs = () => {
	const [selected, setSelected] = React.useState<ISelectedFilters>({})
	const [tab, setTab] = React.useState(0)
	const [page, setPage] = React.useState(0)

	let urlJobId: string | null = null
	if (typeof window !== 'undefined') {
		const queryStrings = queryString.parse(location.search)
		urlJobId = (queryStrings.job_id as string) || null
	}

	const { data, loading, error, refetch } = useGetContractorJobs({
		variables: {
			jobId: urlJobId,
			page,
			view: tab === 0 ? 'OPEN' : 'CLOSED',
			trades:
				selected && selected['Job Type'] && selected['Job Type'].length > 0
					? (selected['Job Type'] as TradeGroup[])
					: undefined
		}
	})

	const [inspectJobId, setInspectJobId] = React.useState(null)
	const [jobNotFound, setJobNotFound] = React.useState(false)
	const _stopInspectingJob = () => {
		if (urlJobId && inspectJobId) {
			navigate('/contractor/jobs')
		}
		setInspectJobId(null)
	}

	function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
		setTab(newValue)
		setPage(0)
	}

	const contractorJobs = ((data && data.contractorJobs) || []).filter(
		j => j.myBid && j.myBid.status === 'HIRED'
	)
	let unfilteredJobs = contractorJobs || []
	if (selected && selected['Job Type'] && selected['Job Type'].length > 0) {
		unfilteredJobs = unfilteredJobs.filter(j =>
			j.trades.find(t => selected['Job Type'].includes(t.group))
		)
	}
	const jobs: IContractorJob[] = unfilteredJobs.map(mapToContractorJob)

	const trades = (data && data.contractor && data.contractor.trades) || []
	const filters = {
		'Job Type': trades.map(t => t.group.replace(/./, x => x.toUpperCase()))
	}

	let tableBody
	if (error) {
		tableBody = (
			<Typography variant="h5">
				There was a problem loading your jobs: {error.message}
			</Typography>
		)
	} else if (loading) {
		tableBody = <Loading />
	} else if (
		data.contractorOngoingJobsCount + data.contractorClosedJobsCount ===
		0
	) {
		tableBody = <Typography variant="h5">No Jobs to Display</Typography>
	} else {
		tableBody = jobs.map(row => (
			<JobRowItem
				key={row.id}
				job={row}
				onClick={() => setInspectJobId(row.id)}
			/>
		))
	}

	if (!inspectJobId && !loading && !error && urlJobId) {
		if (jobs.map(j => j.id).includes(urlJobId)) {
			setInspectJobId(urlJobId)
		} else {
			setJobNotFound(true)
		}
	}

	return (
		<Outer>
			<StyledCard>
				<AppBar position="static" color="default">
					<Tabs value={tab} onChange={handleTabChange} centered>
						<Tab
							label={`Open Jobs (${data?.contractorOngoingJobsCount || 0})`}
						/>
						<Tab
							label={`Closed Jobs (${data?.contractorClosedJobsCount || 0})`}
						/>
					</Tabs>
				</AppBar>
				<Inner>
					<TablePre>
						<HelperText>{tab === 0 ? openText : closedText}</HelperText>
						<FilterView
							selected={selected}
							onChangeSelected={setSelected}
							filters={filters}
						/>
					</TablePre>
					<ResponsiveTable>
						<StyledTable>
							<TableHead>
								<TableRow>
									<TableCell>Job Type</TableCell>
									<TableCell align="right">Customer</TableCell>
									<TableCell align="right">Address</TableCell>
									<TableCell align="right">Accepted Bid</TableCell>
									<TableCell align="right">
										{tab === 0 ? 'Target Date' : 'Date Closed'}
									</TableCell>
									<TableCell align="right" />
								</TableRow>
							</TableHead>
							<TableBody>{tableBody}</TableBody>
						</StyledTable>
					</ResponsiveTable>
				</Inner>
			</StyledCard>
			<ResponsiveModal
				dark
				style={{ height: 800 }}
				open={!!inspectJobId}
				onClose={() => {
					refetch()
					_stopInspectingJob()
				}}
			>
				<ContractorJobModal
					job={!!inspectJobId && jobs.find(j => j.id === inspectJobId)}
				/>
			</ResponsiveModal>
			<ErrorSnackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={jobNotFound}
				autoHideDuration={5000}
				onClose={() => setJobNotFound(false)}
				ContentProps={{
					'aria-describedby': 'message-id'
				}}
				message={
					<span id="message-id">Could not find the job on your open jobs.</span>
				}
			/>
			<PaginationContainer>
				<Pagination
					count={
						tab === 0
							? (data?.contractorOngoingJobsCount || 0)
							: (data?.contractorClosedJobsCount || 0)
					}
					page={page}
					setPage={p => setPage(p)}
				/>
			</PaginationContainer>
		</Outer>
	)
}

export default OpenJobs

const PaginationContainer = styled.div`
	display: flex;
	align-self: flex-end;
	flex-direction: column;
	padding-right: 22px;
	align-items: flex-end;
`

const StyledCard = styled.div`
	&& {
		margin: 24px;
		@media (max-width: 748px) {
			margin: 16px 0;
			padding: 0;
		}
	}
`

const HelperText = styled(Typography)`
	&& {
		font-size: 13px;
		font-weight: 500;
		opacity: 0.85;
		margin: 8px 8px 16px 8px;
	}
`

const Outer = styled.div`
	@media (max-width: 850px) {
		margin-top: 52px;
	}
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
`

const Inner = styled.div`
	overflow-x: auto;
	padding: 24px;
	background: white;
`

const StyledTable = styled(Table)`
	min-width: 400px;
	* {
		border-color: rgba(255, 255, 255, 0.3);
	}

	td:last-child {
		padding-right: 4px;
		padding-left: 4px;
	}
`

const TablePre = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const ErrorSnackbar = styled(Snackbar)`
	& > div {
		background-color: darkred;
	}
`
