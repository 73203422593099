import React from 'react'
import ContractorSidebar from '../../components/contractorSidebar'
import OpenJobs from '../../components/contractor/ContractorJobs/OpenJobs'
import SEO from '../../components/seo/seo'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';

const NewJob = () => (
	<DisastrousErrorHandler>
		<ContractorSidebar>
			<SEO title="Active jobs" keywords={['3d', 'construction']} />
			<OpenJobs />
		</ContractorSidebar>
	</DisastrousErrorHandler>
)

export default NewJob;
