import React from 'react'
import styled from 'styled-components'
import {
	IContractorJob,
	IBiddableProperty,
	IBiddablePropertyDetails
} from '../../../pages/jobs/index'
import { TableRow, TableCell, Icon } from '@material-ui/core'
import { Chat } from '@material-ui/icons';

function isDetailedProperty(p: IBiddableProperty | IBiddablePropertyDetails): p is IBiddablePropertyDetails {
	return !!(p as IBiddablePropertyDetails).houseNumber
}

const _deriveAddress = (property: IContractorJob['property']) => {
	if (!isDetailedProperty(property)) {
		return `${property.city}, ${property.state} ${property.zipCode}`
	}
	return `${property.houseNumber} ${property.streetName}` + '\n'
		+ `${property.city}, ${property.state} ${property.zipCode}`
}

const _deriveName = (property: IContractorJob['property']) => {
	if (!isDetailedProperty(property)) {
		return property.owner.firstName
	}
	return `${property.owner.firstName} ${property.owner.lastName}`
}

const JobRowItem = ({ job, onClick }: { job: IContractorJob; onClick?: () => void }) => (
	<StyledRow key={job.id} onClick={onClick}>
		<TableCell component="th" scope="job">
			<IconRow>
				<Icon>
					<img src={require(`../../../images/jobIcons/${job.iconSlug}.svg`)} />
				</Icon> {job.title}
			</IconRow>
		</TableCell>
		<TableCell align="right">{_deriveName(job.property)}</TableCell>
		<TableCell align="right" style={{
			whiteSpace: 'pre',
			lineHeight: 1.4,
		}}>{_deriveAddress(job.property)}</TableCell>
		<TableCell align="right">{
			job.myBid &&
			job.myBid.amount.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
			})
		}</TableCell>
		<TableCell align="right">{new Date(job.completedOn || job.date).toLocaleDateString()}</TableCell>
		<TableCell align="right">{job.hasUnreadMessages && <Chat color="primary" />}</TableCell>
	</StyledRow>
)

export default React.memo(JobRowItem)

const IconRow = styled.div`
	display: flex;
	align-items: center;
	> * {
		margin-right: 6px;
	}
`

const StyledRow = styled(TableRow)`
	&& {
		cursor: pointer;
		vertical-align: top;
		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}
`
